* {
  box-sizing: border-box;
  outline: none;
  font-family: Karla, Helvetica Neue, sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "ITC Avant Garde Gothic LT", "Helvetica Neue", sans-serif;
}

body,
html {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: "ITC Avant Garde Gothic LT";
  src: local("ITC Avant Garde Gothic LT Bold"), local("AvantGardeLT-Bold"),
    url("./fonts/AvantGardeLT-Bold.woff2") format("woff2"), url("./fonts/AvantGardeLT-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
